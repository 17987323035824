import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Header from "../components/Header"
import SEO from "../components/seo"
import { css } from "@emotion/react"
import NotFOundSVG from "../images/not-found.svg"

export default () => {
  return (
    <>
      <SEO />
      <Helmet
        title="Not found"
        meta={[
          {
            name: "description",
            content: "not found",
          },
        ]}
      >
        <html lang="uk" />
      </Helmet>

      <Header />

      <div
        css={css`
          height: 89vh;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          p {
            color: #525151;
            margin: 0;
            font-size: 16px;
            font-family: "Rubik", Arial, Helvetica, sans-serif;
            margin-top: 1em;
          }
        `}
      >
        <img
          src={NotFOundSVG}
          css={css`
            max-width: 300px;
            margin-top: 3em;
            margin-bottom: 4em;
          `}
        />

        <p>404</p>
      </div>

      <Footer />
    </>
  )
}
